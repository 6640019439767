/* ./src/index.css */
@tailwind base;
@tailwind components;

@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'SofiaPro';
    font-weight: 400;
    src: url('../public/fonts/SofiaPro/SofiaProRegular.woff') format('woff');
  }
  @font-face {
    font-family: 'SofiaPro';
    font-weight: 500;
    src: url('../public/fonts/SofiaPro/SofiaProMedium.woff') format('woff');
  }
  @font-face {
    font-family: 'SofiaPro';
    font-weight: 600;
    src: url('../public/fonts/SofiaPro/SofiaProSemiBold.woff') format('woff');
  }
  @font-face {
    font-family: 'SofiaPro';
    font-weight: 700;
    src: url('../public/fonts/SofiaPro/SofiaProBold.woff') format('woff');
  }
  @font-face {
    font-family: 'SofiaPro';
    font-weight: 800;
    src: url('../public/fonts/SofiaPro/SofiaProBlack.woff') format('woff');
  }
  @font-face {
    font-family: 'SofiaPro';
    font-weight: 300;
    src: url('../public/fonts/SofiaPro/SofiaProLight.woff') format('woff');
  }
  @font-face {
    font-family: 'SofiaPro';
    font-weight: 200;
    src: url('../public/fonts/SofiaPro/SofiaProExtraLight.woff') format('woff');
  }
  @font-face {
    font-family: 'SofiaPro';
    font-weight: 100;
    src: url('../public/fonts/SofiaPro/SofiaProUltraLight.woff') format('woff');
  }
  @font-face {
    font-family: 'SofiaPro';
    src: url('../public/fonts/SofiaPro/SofiaProRegular.woff') format('woff');
  }
  @font-face {
    font-family: 'SofiaPro';
    src: url('../public/fonts/SofiaPro/SofiaProRegular.woff') format('woff');
  }
}

@layer components {
  .silver-full-round-with-shadow {
    @apply bg-silver drop-shadow-[0_1px_1px_rgba(0,0,0,0.125)] rounded-full border-0;
  }
  .default-shadow {
    @apply drop-shadow-[0_1px_1px_rgba(0,0,0,0.125)];
  }
  .without-ring {
    @apply focus:ring-0 focus:ring-offset-0;
  }
}

.textEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 9ch;
}

.shadow-unwrap-1 {
  box-sizing: border-box;
  box-shadow: 6px 6px 0px #cd5a76;
}

.shadow-unwrap-1-2 {
  box-sizing: border-box;
  box-shadow: 2px 2px 0px #cd5a76;
}

.shadow-unwrap-1-2:hover,
.shadow-unwrap-1-2:active {
  box-shadow: 2px 2px 0px #cd5a76;
}

.shadow-unwrap-1-2-hover {
  box-sizing: border-box;
  box-shadow: none;
  transition: all 0.2s ease;
}

.shadow-unwrap-1-2-hover:hover,
.shadow-unwrap-1-2-hover:active {
  box-shadow: 2px 2px 0px #cd5a76;
}

.shadow-unwrap-2 {
  box-sizing: border-box;
  box-shadow: 3px 3px 0px #eab756;
}

.shadow-unwrap-3 {
  box-sizing: border-box;
  box-shadow: 8px 8px 0px #b7dede;
}

.scroll-shadows {
  background:
  /* Shadow Cover TOP */ linear-gradient(white 30%, rgba(255, 255, 255, 0)) center top,
    /* Shadow Cover BOTTOM */ linear-gradient(rgba(255, 255, 255, 0), white 70%) center bottom,
    /* Shadow TOP */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0)) center top,
    /* Shadow BOTTOM */ radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0)) center bottom;

  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
}
/* #pagination-container li:not(:first-child):not(:last-child) {
    display: none;
} */

.cream-bg {
  background-color: #f4ece6;
}

/* Ensuring integration cards are same height */
.integration-content {
  min-height: 210px;
}

.react-datepicker__input-container input {
  @apply block w-full text-base md:text-sm bg-white border border-blueberry rounded;
}

.react-datepicker-popper {
  @apply z-40 w-72 text-sm bg-white  shadow-unwrap-1-2 mt-1 px-3 py-2 border-2 border-blueberry rounded;
}

.react-datepicker-left {
  @apply absolute left-0 right-auto top-11 transform-none !important;
}

.react-datepicker-right {
  @apply absolute right-0 left-auto top-11 transform-none !important;
}

.react-datepicker__portal {
  @apply absolute z-10 w-72 text-sm transform-none bg-white  px-3 py-2 top-12 right-0 border-2 border-gray-200 rounded;
}

.react-datepicker__month-container {
  @apply flex flex-col;
}

.react-datepicker__month {
  @apply flex flex-col;
}

.react-datepicker__current-month {
  @apply ml-2.5 text-lg font-semibold text-gray-800;
}

.react-datepicker__week {
  @apply flex justify-around;
}

.react-datepicker__day-names {
  @apply flex justify-around text-blueberry font-medium text-center text-xs;
}

.react-datepicker__day-name {
  @apply w-8 h-8 flex items-center justify-center py-1 rounded-full;
}

.react-datepicker__navigation {
  @apply absolute top-2;
}

.react-datepicker__navigation--previous {
  @apply right-12 w-8 h-8 rounded transition flex items-center justify-center hover:bg-blueberry hover:text-milk;
}

.react-datepicker__navigation--next {
  @apply right-4 w-8 h-8 rounded transition flex items-center justify-center hover:bg-blueberry hover:text-milk;
}

.react-datepicker__day {
  @apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-sm leading-loose transition text-blueberry rounded;
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-40 hover:bg-transparent;
}

.react-datepicker__day--outside-month {
  @apply text-gray-300;
}

.react-datepicker__day--in-range {
  @apply bg-gray-200;
}

.react-datepicker__day--in-selecting-range {
  @apply bg-blue-200;
}

.react-datepicker__day--selecting-range-start {
  @apply bg-white border-2 border-blue-500;
}

.react-datepicker__day--selecting-range-end {
  @apply bg-white border-2 border-blue-500;
}

.react-datepicker__day--selected {
  @apply bg-blue-500 text-white;
}

.react-datepicker__day--range-start {
  @apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white;
}

.react-datepicker__day--range-end {
  @apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white;
}

.rdp {
  @apply m-4;
}

/* Hide elements for devices that are not screen readers */
.rdp-vhidden {
  @apply box-border p-0 m-0 bg-transparent border-0 appearance-none absolute top-0 w-[1px] h-[1px] overflow-hidden;
}

/* Buttons */
.rdp-button_reset {
  @apply appearance-none relative m-0 p-0 cursor-default outline-none hover:bg-blueberry rounded-md duration-300;
}

.rdp-button {
  @apply border-2 border-solid border-transparent hover:text-white;
}

.rdp-button[aria-disabled='true'] {
  @apply opacity-25 pointer-events-none;
}

.rdp-button:not([aria-disabled='true']) {
  @apply cursor-pointer;
}

.rdp-button:focus,
.rdp-button:active {
  /*  @apply border-2 border-solid border-blueberry; */
}

/* .rdp-button:hover:not([aria-disabled='true']) {
  @apply bg-white;
} */

.rdp-months {
  @apply flex;
}

.rdp-month {
  @apply mt-0 ml-4 text-sm;
}

.rdp-month:first-child {
  @apply ml-0;
}

.rdp-month:last-child {
  @apply mr-0;
}

.rdp-table {
  @apply m-0 max-w-[280px] border-collapse;
}

/*Convert all normal css inside classes to Tailwind CSS with @apply*/
.rdp-with_weeknumber .rdp-table {
  @apply border-collapse max-w-xs;
}

.rdp-caption {
  @apply flex items-center justify-between p-0 text-left;
}

.rdp-multiple_months .rdp-caption {
  @apply relative block text-center;
}

.rdp-caption_dropdowns {
  @apply relative inline-flex;
}

.rdp-caption_label {
  @apply relative z-10 inline-flex items-center m-0 pt-0 pl-1 whitespace-nowrap text-blueberry border-2 border-solid border-transparent text-[140%] font-bold;
}

.rdp-nav {
  @apply whitespace-nowrap;
}

.rdp-multiple_months .rdp-caption_start .rdp-nav {
  @apply absolute top-1/2 -translate-y-1/2;
}

.rdp-multiple_months .rdp-caption_end .rdp-nav {
  @apply absolute top-1/2 right-0 -translate-y-1/2;
}

.rdp-nav_button {
  @apply inline-flex items-center justify-center w-10 h-10 p-1;
}

/* ---------- */
/* Dropdowns  */
/* ---------- */

.rdp-dropdown_year,
.rdp-dropdown_month {
  @apply relative inline-flex items-center;
}

.rdp-dropdown {
  @apply appearance-none absolute z-20 top-0 bottom-0 left-0 w-full m-0 p-0 opacity-0 border  bg-transparent;
}

.rdp-dropdown[disabled] {
}

/* .rdp-dropdown:focus:not([disabled]) + .rdp-caption_label,
.rdp-dropdown:active:not([disabled]) + .rdp-caption_label {
    @apply border-2 border-solid border-blueberry border-r-[6px]
} */

.rdp-dropdown_icon {
  @apply ml-[5px];
}

.rdp-head {
  @apply border-0;
}

.rdp-head_row,
.rdp-row {
  @apply h-full;
}

.rdp-head_cell {
  @apply align-middle uppercase text-xs font-bold text-center h-10 p-0;
}

.rdp-tbody {
  @apply border-0;
}

.rdp-tfoot {
  @apply m-2;
}

.rdp-cell {
  @apply w-10 h-10 p-0 text-center;
}

.rdp-weeknumber {
  @apply text-xs;
}

.rdp-weeknumber,
.rdp-day {
  @apply flex overflow-hidden items-center justify-center box-border w-10 h-10 m-0 border-2 border-solid border-transparent;
}

.rdp-day_today:not(.rdp-day_outside) {
  @apply font-bold;
}

.rdp-day_selected:not([aria-disabled='true']),
.rdp-day_selected:focus:not([aria-disabled='true']),
.rdp-day_selected:active:not([aria-disabled='true']),
.rdp-day_selected:hover:not([aria-disabled='true']) {
  @apply text-white bg-blueberry;
}

.rdp-day_selected:focus:not([aria-disabled='true']) {
  @apply border-2 border-solid border-blueberry;
}

.rdp-day_range_start {
  @apply bg-blue-500 text-white;
}
/* .rdp:not([dir='rtl']) .rdp-day_range_start:not(.rdp-day_range_end) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rdp:not([dir='rtl']) .rdp-day_range_end:not(.rdp-day_range_start) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rdp[dir='rtl'] .rdp-day_range_start:not(.rdp-day_range_end) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rdp[dir='rtl'] .rdp-day_range_end:not(.rdp-day_range_start) {
  border-top-right-radius: 0;z
  border-bottom-right-radius: 0;
}

.rdp-day_range_end.rdp-day_range_start {
  border-radius: 100%;
}

.rdp-day_range_middle {
  border-radius: 0;
} */

/* Chrome */
.containerScrollbar::-webkit-scrollbar {
  width: 10px;
}

.containerScrollbar::-webkit-scrollbar-track {
  /*background-color: grey;*/
  box-shadow: inset 0 0 0px grey;
  border-radius: 15px;
}

.containerScrollbar::-webkit-scrollbar-thumb {
  background-color: #464c82;
  border-radius: 15px;
  /*border: 1px solid red;*/
}

/* IE */
.containerScrollbar {
  scrollbar-face-color: #464c82;
}

/* FireFox */
.containerScrollbar {
  scrollbar-color: #464c82;
}

/* View Scrollbar */
.containerScrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
}

/* Chrome */
.containerScrollbarDark::-webkit-scrollbar {
  width: 7px;
}

.containerScrollbarDark::-webkit-scrollbar-track {
  /*background-color: grey;*/
  box-shadow: inset 0 0 0px silver;
  border-radius: 15px;
}

.containerScrollbarDark::-webkit-scrollbar-thumb {
  background-color: silver;
  border-radius: 15px;
  /*border: 1px solid red;*/
}

/* IE */
.containerScrollbarDark {
  scrollbar-face-color: silver;
}

/* FireFox */
.containerScrollbarDark {
  scrollbar-color: silver;
}

/* View Scrollbar */
.containerScrollbarDark {
  overflow-y: scroll;
  overflow-x: hidden;
}
.tippy-box {
  font-family: 'SofiaPro';
}

.bottom-arrow {
  border-bottom: 5px solid rgb(247, 247, 247);
}

.bottom-arrow:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  margin: 0px 0px 0px -15px;
  width: 0;
  height: 0;
  border-top: 15px solid rgb(247, 247, 247);
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
}
.react-joyride__tooltip {
  font-family: 'SofiaPro';
}

.react-grid-placeholder {
  background-color: silver !important; /* Replace with your desired color */
  opacity: 0.8; /* Optional: Adjust the opacity as needed */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
